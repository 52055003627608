interface GHTrustedTypePolicyOptions {
  createHTML: (input: string, ...args: unknown[]) => string
  createScript: (input: string, ...args: unknown[]) => string
  createScriptURL: (input: string, ...args: unknown[]) => string
}

interface GHTrustedTypePolicyWithName extends GHTrustedTypePolicyOptions {
  name: string
}

function unimplemented(functionName: string) {
  return () => {
    throw new TypeError(`The policy does not implement the function ${functionName}`)
  }
}

const fallbacks = {
  createHTML: unimplemented('createHTML'),
  createScript: unimplemented('createScript'),
  createScriptURL: unimplemented('createScriptURL')
}

const tinyfill = {
  createPolicy: (name: string, rules: Partial<GHTrustedTypePolicyOptions>): GHTrustedTypePolicyWithName => ({
    name,
    ...fallbacks,
    ...rules
  })
}

interface GlobalThisWithMaybeTrustedTypes {
  trustedTypes?: typeof tinyfill
}

export const ghTrustedTypes = (globalThis as GlobalThisWithMaybeTrustedTypes).trustedTypes ?? tinyfill

// This export is only for testing. Do not import in production code.
export const tinyfillForTesting = tinyfill
