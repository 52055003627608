import {announce} from '../aria-live'
import {SOFT_NAV_STATE} from '../soft-nav/state'

/** Ensure that when a turbo frame gets swapped, the page title is announced */
document.addEventListener(SOFT_NAV_STATE.FRAME_UPDATE, function () {
  announce(`${document.title}`)
})

/** Ensure that when a turbo drive navigation happens, the page title is announced */
document.addEventListener(SOFT_NAV_STATE.SUCCESS, function (event) {
  if (event.mechanism === 'turbo') {
    announce(`${document.title}`)
  }
})
