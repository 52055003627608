import {sendEvent} from '../hydro-analytics'
import {loaded} from '../document-ready'

const vitalsToHydro = ['fcp', 'lcp', 'fid', 'inp', 'cls', 'hpc', 'ttfb'] as const

interface HydroStat {
  react?: boolean
  hpcMechanism?: PlatformBrowserSoftNavigationMechanism
  ssr?: boolean
  hpcSoft?: boolean
  hpc?: string
  ttfb?: string
  fcp?: string
  lcp?: string
  fid?: string
  inp?: string
  cls?: string
}

let queued: HydroStat | undefined

/**
 * Batched report of vital to hydro
 */
export function sendStatToHydro(stat: PlatformBrowserPerformanceWebVitalTiming) {
  let hydroStat: HydroStat | undefined

  if (!document.querySelector('[data-hydrostats="publish"]')) return

  for (const vital of vitalsToHydro) {
    if (stat[vital] !== undefined && stat[vital]! < 60_000) {
      if (!hydroStat) {
        hydroStat = queueStat()
        hydroStat.react = !!document.querySelector('react-app')
        hydroStat.ssr = stat.ssr
      }
      hydroStat[vital] = stat[vital]!.toPrecision(6)
      if (vital === 'hpc') {
        hydroStat.hpcMechanism = stat.mechanism
        hydroStat.hpcSoft = stat.soft
      }
      break
    }
  }
}

/**
 * Create a new stat object and schedule it to be sent to hydro
 */
function queueStat(): HydroStat {
  if (!queued) {
    queued = {}
    scheduleSend()
  }
  return queued
}

/**
 * Schedule a send to hydro
 */
async function scheduleSend() {
  await loaded
  window.requestIdleCallback(send)
}

/**
 * Send the queued event to hydro
 */
function send() {
  sendEvent('web-vital', queued as Record<string, string | number | boolean | null | undefined>)
  queued = undefined
}
