import {failSoftNav, initSoftNav, renderedSoftNav, startSoftNav, succeedSoftNav} from './state'

const SOFT_NAV_EXTERNAL_EVENTS = Object.freeze({
  INITIAL: 'soft-nav:external:initial',
  START: 'soft-nav:external:start',
  SUCCESS: 'soft-nav:external:success',
  ERROR: 'soft-nav:external:error',
  RENDER: 'soft-nav:external:render'
})

document.addEventListener(SOFT_NAV_EXTERNAL_EVENTS.INITIAL, initSoftNav)

document.addEventListener(SOFT_NAV_EXTERNAL_EVENTS.START, e => {
  startSoftNav((e as CustomEvent).detail.mechanism)
})

document.addEventListener(SOFT_NAV_EXTERNAL_EVENTS.SUCCESS, () => succeedSoftNav())
document.addEventListener(SOFT_NAV_EXTERNAL_EVENTS.ERROR, () => failSoftNav())
document.addEventListener(SOFT_NAV_EXTERNAL_EVENTS.RENDER, () => renderedSoftNav())
