// History back link
//
// DEPRECATED: please do not use this behavior in new places.
//
// Links tagged with "js-turbo-history-navigate" classname gain the behavior of popping the
// last item from the browser history stack if the link's href matches the URL of
// the last history item. This behavior is suitable for links whose purpose it so
// bring users back to the previous page.

import {getBackURL, getForwardURL, setState} from '../../history'
import {endSoftNav} from '../../soft-nav/state'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {isTurboEnabled} from './utils'

// okay to call `on` outside of global scope in this case as it's called conditionally to a feature flag
on('turbo:click', '.js-turbo-history-navigate', function (ev) {
  if (!isTurboEnabled()) return
  const event = ev as CustomEvent
  const originalClick = event.detail.originalEvent as MouseEvent

  if (!(event.currentTarget instanceof HTMLAnchorElement)) return

  if (originalClick.shiftKey || originalClick.metaKey || originalClick.ctrlKey || originalClick.altKey) return
  // set current click state for history
  setState(history.state)

  const backURL = getBackURL()
  const forwardURL = getForwardURL()

  if (backURL && event.currentTarget.href === backURL) {
    event.preventDefault()
    originalClick.preventDefault()
    history.back()

    endSoftNav()
  } else if (forwardURL && event.currentTarget.href === forwardURL) {
    event.preventDefault()
    originalClick.preventDefault()
    history.forward()

    endSoftNav()
  }
})
