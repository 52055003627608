import type {AliveData} from '@github/alive-client'

type Entry = {name: string; data: AliveData; arrived: number}
const cache = new Map<string, Entry>()

function store(event: Event) {
  const {name, data, cached} = (event as CustomEvent).detail
  if (cached) return
  const entry = {name, data: {...data}, arrived: Date.now()}
  entry.data.wait = 0
  cache.set(name, entry)
}

// Cache socket messages for future delivery.
document.addEventListener('socket:message', store, {capture: true})
