// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {sendData} from '../hydro-tracking'
import safeStorage from '../safe-storage'

const {getItem} = safeStorage('localStorage')

declare const process: {
  env: {
    NODE_ENV: string
  }
}

function debugPayload(debugKey: string, payload: string): void {
  if (process.env.NODE_ENV === 'development') {
    if (getItem(debugKey) === 'true') {
      /* eslint eslint-comments/no-use: off */
      /* eslint-disable no-console */
      try {
        const parsed = JSON.parse(payload)
        const eventName = parsed?.payload?.event_name ?? parsed?.event_type
        console.group(`[${debugKey}] ${eventName}`)
        console.table(parsed)
        const keys = Object.keys(parsed?.payload || {})
        for (const key of keys) {
          const object = parsed.payload[key]
          if (object instanceof Object && Object.keys(object).length > 0) {
            console.group(key)
            console.table(object)
            console.groupEnd()
          }
        }
        console.groupEnd()
      } catch (e) {
        console.group(`[hydro ${debugKey}] - failed to parse payload`)
        console.log(payload)
        console.groupEnd()
      }
      /* eslint-enable no-console */
    }
  }
}

on('click', '[data-hydro-click]', function (event) {
  const el = event.currentTarget
  const payload = el.getAttribute('data-hydro-click') || ''
  const hmac = el.getAttribute('data-hydro-click-hmac') || ''
  const hydroClientContext = el.getAttribute('data-hydro-client-context') || ''
  debugPayload('hydro-debug.click', payload)
  sendData(payload, hmac, hydroClientContext)
})
